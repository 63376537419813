import { Box, Paper, Switch, Typography } from "@material-ui/core";
import {
  ProfitAndLossColumn,
  ProfitAndLossData,
  ProfitAndLossTable,
} from "./profitAndLossTable";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  formatCurrency,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";

import { CurrentStore } from "~/typedef/store";
import { DateRange } from "~/typedef/date";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { SellType } from "~/pages/singleChannel/profitability/vendor/profitabilityProduct";
import { get } from "lodash";
import { numberWithCommas } from "~/utils/utils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useVendorProfitabilityByVendorCodesDataQuery } from "~/store/mystore/vendorProfitability.redux";

interface VendorProfitAndLossbyVendorCodesProps {
  title: string;
  store: CurrentStore;
  currentRange: DateRange;
  currentCurrency: string;
}

const PanelWrapper = styled(Paper)`
  overflow: hidden;
`;

const PanelHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
`;

const LoadingWrapper = styled(Box)`
  width: 100%;
  height: 200px;
`;

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const DataWrapper = styled(Box)`
  overflow-x: auto;
`;

const VendorProfitAndLossByVendorCodes =
  memo<VendorProfitAndLossbyVendorCodesProps>(
    function VendorProfitAndLossByVendorCodes({
      title,
      store,
      currentRange,
      currentCurrency,
    }) {
      const sellType = SellType.SELL_IN;
      const currencyRates = useTypedSelector(
        (state) => state.globalVar.currencyRates
      );

      const containerRef = useRef<HTMLDivElement | null>(null);

      const [showBreakdown, setShowBreakdown] = useState(false);
      const [loading, setLoading] = useState(false);
      const { t } = useTranslation();

      const currency = getCurrencyByCountryCode[store.marketplaceCountry];

      const { isFetching, totals, dataByVendorCodes, vendorCodes } =
        useVendorProfitabilityByVendorCodesDataQuery(
          {
            mid: store.merchantId,
            marketplaceType: store.marketplace,
            marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
            countryCode: store.marketplaceCountry,
            currentRange,
          },
          {
            selectFromResult: ({ data, isFetching }) => {
              return {
                vendorCodes: Object.keys(data?.groupedData || {}),
                dataByVendorCodes: data?.groupedData || {},
                totals: data?.totals,
                isFetching,
              };
            },
          }
        );

      useEffect(() => {
        // force artificial loading state to show loading indicator
        // when user toggles the detailed P&L, loading indicator makes the transition smoother
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 500); // 0.5 seconds
      }, [showBreakdown]);

      const handleCurrencyValue = useCallback(
        (value: number) =>
          value
            ? value < 0
              ? // if value is negative, show it in brackets
                `(${formatCurrency(
                  Math.abs(value),
                  currencyRates,
                  currency,
                  currentCurrency
                )})`
              : formatCurrency(value, currencyRates, currency, currentCurrency)
            : "-",
        [currencyRates, currentCurrency, currency]
      );

      const getFormattedDataByField = useCallback(
        (
          keys: string[],
          field: string,
          type: "int" | "percentage" | "currency" = "currency"
        ) =>
          keys.reduce<Record<string, string>>((acc, key) => {
            acc[key] =
              type === "currency"
                ? handleCurrencyValue(get(dataByVendorCodes[key], field, 0))
                : type === "percentage"
                ? `${get(dataByVendorCodes[key], field, 0).toFixed(2)}%`
                : numberWithCommas(get(dataByVendorCodes[key], field, 0));
            return acc;
          }, {}),
        [dataByVendorCodes, handleCurrencyValue]
      );

      const formattedTableData = useMemo((): {
        income: ProfitAndLossData[];
        expense: ProfitAndLossData[];
        profit: ProfitAndLossData[];
        metrics: ProfitAndLossData[];
      } => {
        if (!totals || !dataByVendorCodes) {
          return {
            income: [],
            expense: [],
            profit: [],
            metrics: [],
          };
        }

        return {
          income: [
            {
              key: t(`vendorProfitAndLoss.submittedPOLabel`),
              tooltip: t(`vendorProfitAndLoss.submittedPOTooltip`),
              total: handleCurrencyValue(get(totals, "submittedPO", 0)),
              ...getFormattedDataByField(vendorCodes, "submittedPO"),
            },
            {
              key: t(`vendorProfitAndLoss.cancelledPOLabel`),
              tooltip: t(`vendorProfitAndLoss.cancelledPOTooltip`),
              total: handleCurrencyValue(get(totals, "cancelledPO", 0)),
              ...getFormattedDataByField(vendorCodes, "cancelledPO"),
            },
            {
              key: t(`vendorProfitAndLoss.rejectedPOLabel`),
              tooltip: t(`vendorProfitAndLoss.rejectedPOTooltip`),
              total: handleCurrencyValue(get(totals, "rejectedPO", 0)),
              ...getFormattedDataByField(vendorCodes, "rejectedPO"),
            },
            {
              key: t(`vendorProfitAndLoss.acceptedPOLabel`),
              tooltip: t(`vendorProfitAndLoss.acceptedPOTooltip`),
              total: handleCurrencyValue(get(totals, "acceptedPO", 0)),
              ...getFormattedDataByField(vendorCodes, "acceptedPO"),
            },
            {
              key: t(`vendorProfitAndLoss.shortagesLabel`),
              tooltip: t(`vendorProfitAndLoss.shortagesTooltip`),
              total: handleCurrencyValue(get(totals, "shortages", 0)),
              ...getFormattedDataByField(vendorCodes, "shortages"),
            },
            {
              key: t(`vendorProfitAndLoss.netReceiptsLabel`),
              bold: true,
              total: handleCurrencyValue(get(totals, "netReceipts", 0)),
              ...getFormattedDataByField(vendorCodes, "netReceipts"),
            },
          ],
          expense: [
            {
              key: t(`vendorProfitAndLoss.adCostLabel`),
              tooltip: t(`vendorProfitAndLoss.adCostTooltip`),
              total: handleCurrencyValue(totals.adCost),
              ...getFormattedDataByField(vendorCodes, "adCost"),
            },
            {
              key: t(`vendorProfitAndLoss.netDeductionsLabel`),
              tooltip: t(`vendorProfitAndLoss.netDeductionsTooltip`),
              total: handleCurrencyValue(totals.expenses.deductions),
              ...getFormattedDataByField(
                vendorCodes,
                "expenses.currentGroupDeduction"
              ),
            },
            ...(showBreakdown
              ? totals.availableDeductions.map((deduction) => ({
                  key: t(`vendorProfitAndLoss.${deduction}Label`),
                  startAlign: false,
                  total: handleCurrencyValue(
                    get(totals, `expenses.individualDeductions.${deduction}`, 0)
                  ),
                  ...getFormattedDataByField(
                    vendorCodes,
                    `expenses.deductions.${deduction}`
                  ),
                }))
              : []),
            {
              key: t(`vendorProfitAndLoss.cogsLabel`),
              tooltip: t(`vendorProfitAndLoss.cogsTooltip`),
              total: handleCurrencyValue(totals.expenses.cogs),
              ...getFormattedDataByField(
                vendorCodes,
                "expenses.currentGroupCogs"
              ),
            },
            {
              key: t(`vendorProfitAndLoss.netChargebacksLabel`),
              tooltip: t(`vendorProfitAndLoss.netChargebacksTooltip`),
              total: handleCurrencyValue(totals.expenses.chargebacks),
              ...getFormattedDataByField(
                vendorCodes,
                "expenses.currentGroupChargeback"
              ),
            },
            ...(showBreakdown
              ? totals.availableChargebacks.map((chargeback) => ({
                  key: chargeback,
                  startAlign: false,
                  total: handleCurrencyValue(
                    get(
                      totals,
                      `expenses.individualChargebacks.${chargeback}`,
                      0
                    )
                  ),
                  ...getFormattedDataByField(
                    vendorCodes,
                    `expenses.chargebacks.${chargeback}`
                  ),
                }))
              : []),
            {
              key: t(`vendorProfitAndLoss.netExpenseLabel`),
              total: handleCurrencyValue(totals.netExpenses),
              bold: true,
              ...getFormattedDataByField(vendorCodes, "totalExpenses"),
            },
          ],
          profit: [
            {
              key: t(`vendorProfitAndLoss.profitLabel`),
              total: handleCurrencyValue(totals.profit),
              bold: true,
              ...getFormattedDataByField(vendorCodes, "profit"),
            },
            {
              key: t(`vendorProfitAndLoss.profitPercentLabel`),
              total: `${totals.profitPercentage.toFixed(2)}%`,
              colorCode: true,
              ...getFormattedDataByField(
                vendorCodes,
                "profitPercentage",
                "percentage"
              ),
            },
          ],
          metrics: [
            {
              key: t(`vendorProfitAndLoss.tacosLabel`),
              total: `${totals.tacos.toFixed(2)}%`,
              ...getFormattedDataByField(vendorCodes, "tacos", "percentage"),
            },

            {
              key: t(`vendorProfitAndLoss.totalFeesLabel`),
              total: `${totals.totalFees.toFixed(2)}%`,
              ...getFormattedDataByField(
                vendorCodes,
                "totalFees",
                "percentage"
              ),
            },
            {
              key: t(`vendorProfitAndLoss.receivedUnitsLabel`),
              total: numberWithCommas(get(totals, "receivedUnits", 0)),
              ...getFormattedDataByField(vendorCodes, "receivedUnits", "int"),
            },
            {
              key: t(`vendorProfitAndLoss.confirmationRateLabel`),
              total: `${get(totals, "confirmationRate", 0).toFixed(2)}%`,
              ...getFormattedDataByField(
                vendorCodes,
                "confirmationRate",
                "percentage"
              ),
            },
            {
              key: t(`vendorProfitAndLoss.deliveryRateLabel`),
              total: `${get(totals, "deliveryRate", 0).toFixed(2)}%`,
              ...getFormattedDataByField(
                vendorCodes,
                "deliveryRate",
                "percentage"
              ),
            },
          ],
        };
      }, [totals, vendorCodes, sellType, showBreakdown]);

      const getColumnWidth = useCallback((columnCount: number) => {
        const containerRefWidth = containerRef?.current?.clientWidth || 0;
        const columnWidth = (containerRefWidth - 300) / columnCount;

        if (columnCount === 1) return "45%";

        return columnWidth < 80 ? "100px" : `${columnWidth}px`;
      }, []);

      const columns = useMemo((): ProfitAndLossColumn[] => {
        return [
          {
            header: t(`profitability.period`),
            key: "key",
            bold: true,
            uppercase: true,
            startAlign: true,
            width: "175px",
            // stick the first column
            stickyLeft: "0",
          },
          {
            header: t("generic.total"),
            key: "total",
            bold: true,
            startAlign: false,
            uppercase: true,
            width: "125px",
            divider: "right",
            // stick the second column
            // this is a workaround to make the second column sticky
            stickyLeft: "175px",
          },
          ...vendorCodes.map((key) => ({
            header: key == "null" ? "" : key,
            key,
            bold: true,
            startAlign: false,
            uppercase: true,
            width: getColumnWidth(vendorCodes.length),
          })),
        ];
      }, [vendorCodes]);

      return (
        <PanelWrapper ref={containerRef}>
          <PanelHeader>
            <Typography variant="h3">{title}</Typography>
            <FlexBox>
              <Typography variant="subtitle1" color="textPrimary" noWrap>
                {t("profitability.expandProfitAndLoss")}
              </Typography>
              <Switch
                size="small"
                checked={showBreakdown}
                onClick={() => {
                  setShowBreakdown(!showBreakdown);
                }}
              />
            </FlexBox>
          </PanelHeader>
          <Box>
            {loading || isFetching ? (
              <LoadingWrapper>
                <PanelLoading />
              </LoadingWrapper>
            ) : (
              <DataWrapper>
                <ProfitAndLossTable
                  {...formattedTableData}
                  columns={columns}
                  monthsCount={vendorCodes.length}
                />
              </DataWrapper>
            )}
          </Box>
        </PanelWrapper>
      );
    }
  );

export default VendorProfitAndLossByVendorCodes;
