import "../../theming/print.css";

import { Box, Grid, MuiThemeProvider, Paper } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  getReportDatesFromPeriod,
} from "@store/utils/dateTimeUtils";
import InventoryHealthTable, {
  TABLE_TYPE as INVENTORY_HEALTH_TABLE_TYPE,
} from "~/modules/vendor/inventoryHealthTable";
import React, { useEffect } from "react";
import RefundAnalyticsChart, {
  CHART_TYPE as REFUND_ANALYTICS_CHART_TYPE,
} from "~/modules/widgets/refundAnalytics/refundAnalyticsChart";
import SalesPerformanceLineChart, {
  CHART_TYPE,
} from "~/modules/widgets/salesPerformanceLineChart";
import ShippedRevenueTrend, {
  CHART_TYPE as SHIPPED_REVENUE_CHART_TYPE,
} from "~/modules/vendor/shippedRevenueTrend";
import TrafficAndConversionTable, {
  TrafficAndConversionTableSelect,
} from "~/modules/trafficAndConversion/trafficAndConversionTable";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";
import styled, { ThemeProvider } from "styled-components";

import AccountHealth from "~/modules/widgets/accountHealth";
import AdSpendInvestmentVsBudgetWidget from "~/modules/widgets/forecasting/adSpendInvestmentVsBudget";
import AdvertisingProducts from "~/modules/marketing/products";
import BuyBoxSummary from "~/modules/widgets/buyboxSummary";
import CLVCohortPurchaseBehaviour from "~/modules/customerLtv/cohortPurchaseBehaviour";
import CLVCohortSummaryPanel from "~/modules/customerLtv/cohortSummaryPanel";
import CLVCohortTrendChart from "~/modules/customerLtv/cohortTrendChart";
import { COMMENTARY_FILTERS } from "~/store/mystore/notes.redux";
import CampaignCustomGroupTable from "~/modules/widgets/customGroups/advertising/campaignCustomGroups";
import CampaignPerformance from "~/modules/widgets/advertising/campaignPerformance";
import CampaignSummary from "~/modules/widgets/overview/marketingCampaignSummary";
import CampaignTypesWidget from "~/modules/widgets/advertising/campaignTypePieChart";
import ClientReportHeader from "./clientReportHeader";
import ClientReportToolbar from "~/components/toolbars/clientReportToolbar";
import CommentsDisplay from "~/modules/widgets/commentsDisplay";
import CustomGroups from "~/modules/widgets/customGroups/customGroups";
import CustomVendorGroups from "../singleChannel/vendorGroup/customVendorGroup";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { CHART_TYPE as GROUPS_CHART_TYPE } from "~/modules/widgets/customGroups/customGroupsChart";
import { CHART_TYPE as GROUP_REVENUE_CHART_TYPE } from "../singleChannel/brandAnalytics/groupRevenue";
import GenericBuyboxTrend from "~/modules/genericBuybox/genericBuyboxTrend";
import { GridWithErrorBoundary } from "~/modules/errorBoundary/errorBoundary";
import { Helmet } from "react-helmet";
import InventoryHealthTotals from "~/modules/vendor/inventoryHealthSummary";
import InventoryHealthTrend from "~/modules/vendor/inventoryHealthTrend";
import InventoryReplenishmentTable from "~/modules/widgets/inventoryManagement/inventoryReplenishmentTable";
import InventoryStatus from "~/modules/widgets/inventoryManagement/inventoryStatus";
import InventorySummary from "~/modules/widgets/overview/inventorySummary";
import { CHART_TYPE as LTV_COHORT_TREND_CHART_TYPE } from "~/modules/customerLtv/cohortTrendChart";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import MarketPlaceOverview from "../../modules/widgets/overview/marketplaceOverview";
import MarketingCampaignSummaryChartAndTotals from "~/modules/widgets/overview/marketingCampaignSummaryChartAndTotals";
import NoReportPage from "./noReportPage";
import OrderedRevenueTable from "~/modules/vendor/orderedRevenueTable";
import OrderedRevenueTotals from "~/modules/vendor/orderedRevenueTotals";
import OrderedRevenueTrend from "~/modules/vendor/orderedRevenueTrend";
import OverviewTotalsAndChart from "~/modules/widgets/advertising/overviewTotalsAndChart";
import PageBlock from "~/components/containers/pageBlock";
import { ProcessedWidgetOption } from "./reportConfigForm";
import ProfitAndLoss from "~/modules/widgets/profitability/profitAndLoss";
import ProfitabilityChart from "~/modules/widgets/profitability/profitabilityChart";
import ProfitabilityProduct from "~/modules/widgets/profitability/profitabilityProduct";
import ProfitabilitySummary from "~/modules/widgets/profitability/profitabilitySummary";
import PurchaseOrderStatusPieChart from "~/modules/widgets/amazonVendor/purchaseOrderStatusPieChart";
import PurchaseOrdersLineChart from "~/modules/widgets/amazonVendor/purchaseOrdersLineChart";
import { QUERY_TYPE } from "~/modules/widgets/amazonVendor/purchaseOrdersPerformance";
import { CHART_TYPE as REPEAT_PURCHASE_CHART_TYPE } from "~/modules/repeatPurchases/salesByCustomerType";
import RefundAnalyticsProductTable from "~/modules/widgets/refundAnalytics/refundAnalyticsProductTable";
import RefundAnalyticsSummary from "~/modules/widgets/refundAnalytics/refundAnalyticsSummary";
import RepeatPurchaseByProduct from "~/modules/repeatPurchases/repeatPurchaseByProduct";
import RepeatPurchaseSummary from "~/modules/repeatPurchases/repeatPurchaseSummary";
import { ReportType } from "./createReport";
import ReviewSummary from "~/modules/widgets/reviews/reviewSummary";
import { CHART_TYPE as SOURCING_SHARE_CHART_TYPE } from "~/modules/vendor/sourcingShareTrend";
import SalesByBrandTable from "~/modules/widgets/salesByBrand";
import SalesByCategoryTable from "~/modules/widgets/salesByCategory";
import SalesByCountryTable from "~/modules/widgets/salesInsights/salesByCountryTable";
import SalesByCustomerType from "~/modules/repeatPurchases/salesByCustomerType";
import SalesByParentWidget from "~/modules/widgets/salesByParent";
import SalesByProductTable from "~/modules/widgets/salesByProduct";
import SalesPerformanceAgainstBudgetWidget from "~/modules/widgets/forecasting/salesPerformanceAgainstBudget";
import SalesPerformanceAgainstForecastWidget from "~/modules/widgets/forecasting/salesPerformanceAgainstForecast";
import SalesPerformanceLineChartYTD from "~/modules/widgets/salesPerformanceLineChartYTD";
import ShippedRevenueByStoresTable from "~/modules/vendor/shippedRevenueByStoresTable/shippedRevenueByStoresTable";
import ShippedRevenueTable from "~/modules/vendor/shippedRevenueTable";
import ShippedRevenueTotals from "~/modules/vendor/shippedRevenueTotals";
import ShippedUnitsSummary from "~/modules/vendor/shippedUnitsSummary";
import SourcingShareTable from "~/modules/vendor/sourcingShareTable";
import SourcingShareTrend from "~/modules/vendor/sourcingShareTrend";
import { Store } from "~/typedef/store";
import SubscribeAndSaveTable from "~/modules/widgets/subscribeAndSave/subscribeAndSaveTable";
import TopPerformingKeywords from "~/modules/widgets/advertising/topPerformingKeywords";
import TopPerformingProductAds from "~/modules/widgets/advertising/topPerformingProductAds";
import TopPerformingProducts from "~/modules/widgets/overview/topPerformingProducts";
import TrafficAndConversionNormalVsB2BTotals from "~/modules/trafficAndConversion/trafficAndConversionNormalVsB2BTotals";
import TrafficAndConversionTotals from "~/modules/trafficAndConversion/trafficAndConversionTotals";
import TrafficAndConversionTrend from "~/modules/trafficAndConversion/trafficAndConversionTrend";
import TrafficMixTrend from "~/modules/trafficAndConversion/trafficMixTrend";
import VendorOverview from "~/modules/widgets/overview/vendorOverview";
import cookies from "browser-cookies";
import { fetchClientReportConfigById } from "~/store/mystore/clientReport.redux";
import { fetchCustomOverridesConfig } from "~/store/customOverrides.redux";
import { fetchFilteredStores } from "~/store/overview/customTags.redux";
import { get } from "lodash";
import { healthDataConfig } from "~/utils/accountHealthUtils";
import i18next from "i18next";
import { jwtInterceptor } from "~/utils/apiUtils/jwt.interceptor";
import moment from "moment-timezone";
import { upsertPersistentAppSettings } from "~/store/persistentAppSettings.redux";
import { useCustomTheme } from "~/hooks/useCustomTheme";
import { useDispatch } from "react-redux";
import useQueryParams from "~/hooks/useQueryParams";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const ReportWrapper = styled(Paper)`
  padding: 2rem;
  margin-top: -160px;
`;

export const ClientReport = ({}) => {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const { t } = useTranslation();

  const [hasSetToken, setHasSetToken] = useState(false);

  const user = useTypedSelector((state) => state.user);
  const clientReport = useTypedSelector((state) => get(state, "clientReport"));

  const filteredStores = useTypedSelector((state) =>
    get(state, "mystore.filteredStores.stores", [])
  );

  const reportId = params.get("id");
  const userId = get(clientReport, "userId");
  const preparedBy = get(clientReport, "preparedBy");
  const userInfo = { _id: userId, organisationId: user?.organisationId };
  const stores: Store[] = get(clientReport, "stores");

  const reportConfig = get(clientReport, "reportConfig");
  const pageSize = get(clientReport, "reportConfig.pageSize");
  const reportToken = get(clientReport, "token");
  const originalDomain = get(clientReport, "originalDomain");
  const authSource = get(clientReport, "authSource");
  const widgets = get(reportConfig, "widgets") as ProcessedWidgetOption[];
  const currentCurrency = get(reportConfig, "currency");
  const currentPeriod = get(reportConfig, "period");
  const timezone = get(reportConfig, "timezone");
  const includeTax = get(reportConfig, "includeTax");
  const conditionalFormatting = get(reportConfig, "conditionalFormatting");
  const currentCompare = get(reportConfig, "comparisonPeriod");
  const selectedTags = get(reportConfig, "selectedTags", []);
  const currentRange = getReportDatesFromPeriod(
    currentPeriod,
    get(reportConfig, "comparisonPeriod", COMPARISON_PERIOD.THISYEAR),
    timezone,
    get(reportConfig, "fromDate"),
    get(reportConfig, "toDate"),
    get(reportConfig, "priorFromDate"),
    get(reportConfig, "priorToDate"),
    get(reportConfig, "interval")
  );
  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  // We want client reports to always render in light mode
  const colorScheme = "light";
  const theme = useCustomTheme(colorScheme);

  useEffect(() => {
    if (reportId && (!reportConfig || clientReport?._id !== reportId)) {
      dispatch(fetchClientReportConfigById({ reportId }));
    }
  }, [reportConfig, reportId, clientReport?._id]);

  useEffect(() => {
    if (reportToken) {
      jwtInterceptor(reportToken, location.pathname);
      setHasSetToken(true);
    }
  }, [reportToken, location.pathname]);

  useEffect(() => {
    if (originalDomain) {
      cookies.set("originalDomain", originalDomain);
    }
    if (authSource) {
      cookies.set("authSource", authSource);
    }
  }, [authSource, originalDomain]);

  useEffect(() => {
    if (!(reportConfig?.reportType === ReportType.MULTI) && stores?.length) {
      const store = stores[0];

      dispatch(
        upsertPersistentAppSettings(
          {
            organisationId: userInfo.organisationId,
            userId: userInfo._id,
            settings: {
              currentStore: {
                marketplace: store.marketplaceType,
                marketplaceSubtype: store.marketplaceSubtype,
                marketplaceCountry: store.marketplaceCountry,
                merchantId: store.merchantId,
                sourceSystemId: store.sourceSystemId,
                storeName: store.storeName,
                isDemoMode: false,
              },
            },
          },
          false // do not saveSettings to mongodb
        )
      );
    }
  }, [reportConfig]);

  const dispatchFetchFilteredStores = () => {
    dispatch(fetchFilteredStores({ tags: selectedTags }));
  };

  useEffect(() => {
    if (reportConfig) {
      dispatchFetchFilteredStores();
    }
  }, [reportConfig]);

  useEffect(() => {
    dispatch(
      fetchCustomOverridesConfig({ originalDomain, authSource, colorScheme })
    );
  }, [hasSetToken, originalDomain, authSource, colorScheme]);

  if (
    !clientReport ||
    clientReport.fetching ||
    (Boolean(reportConfig) && !hasSetToken)
  ) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <LoadingIndicator />
      </Box>
    );
  }

  if (
    !reportId ||
    (!clientReport.fetching && !reportConfig) ||
    !widgets.length ||
    !reportToken ||
    !hasSetToken
  ) {
    return <NoReportPage />;
  }

  const getSelectedConfigurationForWidget = (id: string): any => {
    const widget = widgets.find((widget) => widget.id === id);
    if (widget) {
      return widget.selectedConfiguration;
    }
  };

  const renderWidgets = () => {
    const singleStore = stores[0];
    const sellerId = ["amazon", "amazon_vendor"].includes(
      singleStore.marketplaceSubtype
    )
      ? singleStore.merchantId.split(" @")[0]
      : undefined;
    const sortedWidgets = widgets.sort((a, b) => a.position - b.position);

    const marketplaceFilters = {
      marketplaceType: singleStore.marketplaceType,
      marketplaceSubtype:
        singleStore.marketplaceSubtype ?? singleStore.marketplaceType,
    };

    return sortedWidgets.map((widget, index) => {
      switch (widget.id) {
        case "salesPerformance":
        case "salesPerformanceWithSalesBudget":
        case "salesPerformanceLineChart":
        case "salesPerformanceLineChartWithSalesBudget":
          return (
            <GridWithErrorBoundary
              key={index}
              item
              xs={12}
              className="panel-grid"
            >
              <SalesPerformanceLineChart
                userInfo={userInfo}
                mid={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : singleStore.merchantId
                }
                marketplaceType={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : singleStore.marketplaceType
                }
                marketplaceSubtype={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : singleStore.marketplaceSubtype
                }
                chartType={{
                  value: CHART_TYPE.Sales,
                  label: t("dashboardWidget.salesPerformance.salesOption"),
                }}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                report={true}
                timezone={timezone}
                currentFilter={{
                  marketplaces: [],
                  countries: [],
                  tags: selectedTags,
                }}
                includeTax={includeTax}
                conditionalFormatting={conditionalFormatting}
                isLineChart={
                  widget.id === "salesPerformanceLineChart" ||
                  widget.id === "salesPerformanceLineChartWithSalesBudget"
                }
                showSalesTarget={
                  widget.id === "salesPerformanceWithSalesBudget" ||
                  widget.id === "salesPerformanceLineChartWithSalesBudget"
                }
              />
            </GridWithErrorBoundary>
          );
        case "salesPerformanceYTD":
          return (
            <GridWithErrorBoundary
              key={index}
              item
              xs={12}
              className="panel-grid"
            >
              <SalesPerformanceLineChartYTD
                userInfo={userInfo}
                mid={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : singleStore.merchantId
                }
                chartType={{
                  value: CHART_TYPE.Sales,
                  label: t("dashboardWidget.salesPerformance.salesOption"),
                }}
                currentCurrency={currentCurrency}
                report={true}
                overview={
                  reportConfig.reportType === ReportType.MULTI
                    ? true
                    : undefined
                }
                timezone={timezone}
                currentFilter={{
                  marketplaces: [],
                  countries: [],
                  tags: selectedTags,
                }}
                includeTax={includeTax}
                conditionalFormatting={conditionalFormatting}
                isLineChart={true}
                interval={currentRange.interval}
              />
            </GridWithErrorBoundary>
          );
        case "salesPerformanceCurrentYear":
        case "salesPerformanceCurrentYearLineChart":
          return widget.currentRange && widget.currentPeriod ? (
            <GridWithErrorBoundary
              key={index}
              item
              xs={12}
              className="panel-grid"
            >
              <SalesPerformanceLineChart
                userInfo={userInfo}
                mid={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : singleStore.merchantId
                }
                marketplaceType={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : singleStore.marketplaceType
                }
                marketplaceSubtype={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : singleStore.marketplaceSubtype
                }
                chartType={{
                  value: CHART_TYPE.Sales,
                  label: t("dashboardWidget.salesPerformance.salesOption"),
                }}
                currentPeriod={widget.currentPeriod}
                currentRange={widget.currentRange}
                currentCurrency={currentCurrency}
                report={true}
                timezone={timezone}
                currentFilter={{
                  marketplaces: [],
                  countries: [],
                  tags: selectedTags,
                }}
                includeTax={includeTax}
                conditionalFormatting={conditionalFormatting}
                isLineChart={
                  widget.id === "salesPerformanceCurrentYearLineChart"
                }
              />
            </GridWithErrorBoundary>
          ) : null;

        case "salesPerformanceAgainstForecast":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <SalesPerformanceAgainstForecastWidget
                currentCurrency={currentCurrency}
                currentFilter={{
                  marketplaces: [],
                  countries: [],
                  tags: selectedTags,
                }}
                marketplaceType={singleStore.marketplaceType}
                currentPeriod={currentPeriod}
                includeTax={includeTax}
                mid={singleStore.merchantId}
                currentRange={currentRange}
                userInfo={userInfo}
                currencyRates={currencyRates}
                report={true}
              />
            </Grid>
          );

        case "salesPerformanceAgainstBudget":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <SalesPerformanceAgainstBudgetWidget
                currentCurrency={currentCurrency}
                currentFilter={{
                  marketplaces: [],
                  countries: [],
                  tags: selectedTags,
                }}
                marketplaceType={singleStore.marketplaceType}
                currentPeriod={currentPeriod}
                includeTax={includeTax}
                mid={singleStore.merchantId}
                currentRange={currentRange}
                userInfo={userInfo}
                currencyRates={currencyRates}
                report={true}
              />
            </Grid>
          );

        case "adSpendInvestmentVsBudget":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <AdSpendInvestmentVsBudgetWidget
                currentCurrency={currentCurrency}
                includeTax={includeTax}
                mid={singleStore.merchantId}
                currentRange={currentRange}
                countryCode={singleStore.marketplaceCountry}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                marketplaceType={singleStore.marketplaceType}
                userInfo={userInfo}
                currencyRates={currencyRates}
                report={true}
              />
            </Grid>
          );

        case "purchaseOrdersPerformance":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <PurchaseOrdersLineChart
                userInfo={userInfo}
                mid={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : singleStore.merchantId
                }
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                currentFilter={{
                  marketplaces: [],
                  countries: [],
                  tags: selectedTags,
                }}
                overview={
                  reportConfig.reportType === ReportType.MULTI
                    ? true
                    : undefined
                }
                timezone={timezone}
                chartType={{
                  value: CHART_TYPE.Sales,
                  label: t("dashboardWidget.salesPerformance.salesOption"),
                }}
                report={true}
                queryBy={QUERY_TYPE.poDate}
                conditionalFormatting={conditionalFormatting}
                isLineChart={false}
              />
            </Grid>
          );
        case "purchaseOrdersPerformanceLineChart":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <PurchaseOrdersLineChart
                userInfo={userInfo}
                mid={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : singleStore.merchantId
                }
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                currentFilter={{
                  marketplaces: [],
                  countries: [],
                  tags: selectedTags,
                }}
                overview={
                  reportConfig.reportType === ReportType.MULTI
                    ? true
                    : undefined
                }
                timezone={timezone}
                chartType={{
                  value: CHART_TYPE.Sales,
                  label: t("dashboardWidget.salesPerformance.salesOption"),
                }}
                report={true}
                queryBy={QUERY_TYPE.poDate}
                conditionalFormatting={conditionalFormatting}
                isLineChart={true}
              />
            </Grid>
          );
        case "purchaseOrdersPerformanceShipDate":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <PurchaseOrdersLineChart
                userInfo={userInfo}
                mid={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : singleStore.merchantId
                }
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                currentFilter={{
                  marketplaces: [],
                  countries: [],
                  tags: selectedTags,
                }}
                overview={
                  reportConfig.reportType === ReportType.MULTI
                    ? true
                    : undefined
                }
                timezone={timezone}
                chartType={{
                  value: CHART_TYPE.Sales,
                  label: t("dashboardWidget.salesPerformance.salesOption"),
                }}
                report={true}
                queryBy={QUERY_TYPE.shippedDate}
                conditionalFormatting={conditionalFormatting}
                isLineChart={false}
              />
            </Grid>
          );
        case "purchaseOrdersPerformanceLineChartShipDate":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <PurchaseOrdersLineChart
                userInfo={userInfo}
                mid={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : singleStore.merchantId
                }
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                currentFilter={{
                  marketplaces: [],
                  countries: [],
                  tags: selectedTags,
                }}
                overview={
                  reportConfig.reportType === ReportType.MULTI
                    ? true
                    : undefined
                }
                timezone={timezone}
                chartType={{
                  value: CHART_TYPE.Sales,
                  label: t("dashboardWidget.salesPerformance.salesOption"),
                }}
                report={true}
                queryBy={QUERY_TYPE.shippedDate}
                conditionalFormatting={conditionalFormatting}
                isLineChart={true}
              />
            </Grid>
          );

        case "purchaseOrdersStatusPieChart":
          return reportConfig.reportType !== ReportType.MULTI ? (
            <Grid key={index} item xs={12} className="panel-grid">
              <PurchaseOrderStatusPieChart
                mid={singleStore.merchantId}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                includeTax={includeTax}
              />
            </Grid>
          ) : null;

        case "vendorOverview":
          return (
            <Grid item xs={12} className="panel-grid">
              <VendorOverview
                report={true}
                conditionalFormatting={conditionalFormatting}
                currentCurrency={currentCurrency}
                currentFilter={{
                  marketplaces: [],
                  countries: [],
                  tags: selectedTags,
                }}
                currentRange={currentRange}
                filteredStores={filteredStores}
                includeTax={includeTax}
                pageSize={pageSize}
              />
            </Grid>
          );

        case "advertisingProducts":
          return (
            <Grid item xs={12} className="panel-grid">
              <AdvertisingProducts
                isReportView
                userInfo={userInfo}
                mid={singleStore.merchantId}
                countryCode={singleStore.marketplaceCountry}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                storeName={singleStore.storeName}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                pageSize={pageSize}
              />
            </Grid>
          );

        case "shippedRevenueByStores":
          return (
            <Grid item xs={12} className="panel-grid">
              <ShippedRevenueByStoresTable
                report={true}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                currentFilter={{
                  marketplaces: [],
                  countries: [],
                  tags: selectedTags,
                }}
                filteredStores={filteredStores}
                view="sourcing"
                pageSize={pageSize}
              />
            </Grid>
          );

        case "salesByCategory":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <SalesByCategoryTable
                report={true}
                mid={singleStore.merchantId}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                pageSize={pageSize}
                timezone={timezone}
                conditionalFormatting={conditionalFormatting}
                includeTax={includeTax}
                condensed={true}
              />
            </Grid>
          );

        case "salesByCountry":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <SalesByCountryTable
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceCountry={singleStore.marketplaceCountry}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                pageSize={pageSize}
                includeTax={includeTax}
                condensed={true}
                report={true}
                conditionalFormatting={conditionalFormatting}
              />
            </Grid>
          );

        case "salesByBrand":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <SalesByBrandTable
                report={true}
                userInfo={userInfo}
                mid={singleStore.merchantId}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                pageSize={pageSize}
                timezone={timezone}
                conditionalFormatting={conditionalFormatting}
                includeTax={includeTax}
                condensed={true}
              />
            </Grid>
          );

        case "topBSR":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <SalesByProductTable
                mid={singleStore.merchantId}
                marketplace={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                type="topBSR"
                sellerId={sellerId}
                countryCode={singleStore.marketplaceCountry}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                includeNoSales={false}
                includeNoInventory={true}
                condensed={true}
                report={true}
                pageSize={pageSize}
                timezone={timezone}
                conditionalFormatting={conditionalFormatting}
                includeTax={includeTax}
              />
            </Grid>
          );

        case "topSellers":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <SalesByProductTable
                mid={singleStore.merchantId}
                marketplace={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                type="topSellers"
                sellerId={sellerId}
                countryCode={singleStore.marketplaceCountry}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                includeNoSales={false}
                includeNoInventory={true}
                condensed={true}
                report={true}
                pageSize={pageSize}
                timezone={timezone}
                conditionalFormatting={conditionalFormatting}
                includeTax={includeTax}
              />
            </Grid>
          );

        case "worstSellers":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <SalesByProductTable
                mid={singleStore.merchantId}
                marketplace={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                type="worstSellers"
                sellerId={sellerId}
                countryCode={singleStore.marketplaceCountry}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                includeNoSales={true}
                includeNoInventory={true}
                condensed={true}
                report={true}
                pageSize={pageSize}
                timezone={timezone}
                conditionalFormatting={conditionalFormatting}
                includeTax={includeTax}
              />
            </Grid>
          );

        case "accountHealth":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <AccountHealth
                report={true}
                userInfo={userInfo}
                mid={singleStore.merchantId}
                market={
                  (singleStore.marketplaceType ||
                    singleStore.marketplaceSubtype) as keyof typeof healthDataConfig
                }
                marketplaceSubtype={
                  singleStore.marketplaceSubtype as keyof typeof healthDataConfig
                }
                condensed={true}
                timezone={timezone}
              />
            </Grid>
          );

        case "accountHealthExpanded":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <AccountHealth
                report={true}
                userInfo={userInfo}
                mid={singleStore.merchantId}
                market={
                  (singleStore.marketplaceType ||
                    singleStore.marketplaceSubtype) as keyof typeof healthDataConfig
                }
                marketplaceSubtype={
                  singleStore.marketplaceSubtype as keyof typeof healthDataConfig
                }
                condensed={false}
                conditionalFormatting={conditionalFormatting}
                timezone={timezone}
              />
            </Grid>
          );

        case "advertisingPerformanceTrend":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <OverviewTotalsAndChart
                userInfo={userInfo}
                mid={singleStore.merchantId}
                countryCode={singleStore.marketplaceCountry}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                currentFilter={{
                  marketplaces: [],
                  countries: [],
                  tags: selectedTags,
                }}
                timezone={timezone}
                showChart={true}
                report={true}
                conditionalFormatting={conditionalFormatting}
                includeTax={includeTax}
                selectedMetric={getSelectedConfigurationForWidget(
                  "advertisingPerformanceTrend"
                )}
              />
            </Grid>
          );

        case "advertisingPerformanceSummary":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <OverviewTotalsAndChart
                userInfo={userInfo}
                mid={singleStore.merchantId}
                countryCode={singleStore.marketplaceCountry}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                currentFilter={{
                  marketplaces: [],
                  countries: [],
                  tags: selectedTags,
                }}
                report={true}
                timezone={timezone}
                showTotals={true}
                conditionalFormatting={conditionalFormatting}
                includeTax={includeTax}
              />
            </Grid>
          );

        case "advertisingCampaignPerformance":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <CampaignPerformance
                report={true}
                userInfo={userInfo}
                mid={singleStore.merchantId}
                countryCode={singleStore.marketplaceCountry}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                pageSize={pageSize}
                conditionalFormatting={conditionalFormatting}
              />
            </Grid>
          );

        case "advertisingTopPerformingKeywords":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <TopPerformingKeywords
                report={true}
                userInfo={userInfo}
                mid={singleStore.merchantId}
                countryCode={singleStore.marketplaceCountry}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                pageSize={pageSize}
                conditionalFormatting={conditionalFormatting}
                condensed={true}
              />
            </Grid>
          );

        case "advertisingTopPerformingProductAds":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <TopPerformingProductAds
                report={true}
                userInfo={userInfo}
                mid={singleStore.merchantId}
                countryCode={singleStore.marketplaceCountry}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                pageSize={pageSize}
                conditionalFormatting={conditionalFormatting}
                condensed={true}
              />
            </Grid>
          );

        case "advertisingPerformanceByCampaignType":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <CampaignTypesWidget
                report={true}
                userInfo={userInfo}
                mid={singleStore.merchantId}
                countryCode={singleStore.marketplaceCountry}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
              />
            </Grid>
          );

        case "storeProfit":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <ProfitAndLoss
                title={t("dashboardWidget.profitAndLoss.mainTitle")}
                store={{
                  marketplace: singleStore.marketplaceType,
                  marketplaceSubtype: singleStore.marketplaceSubtype,
                  marketplaceCountry: singleStore.marketplaceCountry,
                  merchantId: singleStore.merchantId,
                  sourceSystemId: singleStore.sourceSystemId,
                  storeName: singleStore.storeName,
                  isDemoMode: false,
                }}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                includeTax={includeTax}
                groupByMonth={false}
                report={true}
              />
            </Grid>
          );

        case "storeProfitAndLoss":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <ProfitAndLoss
                title={t("dashboardWidget.profitAndLoss.mainTitle")}
                store={{
                  marketplace: singleStore.marketplaceType,
                  marketplaceSubtype: singleStore.marketplaceSubtype,
                  marketplaceCountry: singleStore.marketplaceCountry,
                  merchantId: singleStore.merchantId,
                  sourceSystemId: singleStore.sourceSystemId,
                  storeName: singleStore.storeName,
                  isDemoMode: false,
                }}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                includeTax={includeTax}
                groupByMonth={false}
                report={true}
              />
            </Grid>
          );

        case "storeProfitAndLossByMonth":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <ProfitAndLoss
                title={t("dashboardWidget.profitAndLoss.mainTitle")}
                store={{
                  marketplace: singleStore.marketplaceType,
                  marketplaceSubtype: singleStore.marketplaceSubtype,
                  marketplaceCountry: singleStore.marketplaceCountry,
                  merchantId: singleStore.merchantId,
                  sourceSystemId: singleStore.sourceSystemId,
                  storeName: singleStore.storeName,
                  isDemoMode: false,
                }}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                includeTax={includeTax}
                groupByMonth={true}
                report={true}
              />
            </Grid>
          );

        case "productProfitability":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <ProfitabilityProduct
                store={{
                  marketplace: singleStore.marketplaceType,
                  marketplaceSubtype: singleStore.marketplaceSubtype,
                  marketplaceCountry: singleStore.marketplaceCountry,
                  merchantId: singleStore.merchantId,
                  sourceSystemId: singleStore.sourceSystemId,
                  storeName: singleStore.storeName,
                  isDemoMode: false,
                }}
                currentCurrency={currentCurrency}
                currentRange={currentRange}
                exchangeRate={getExchangeRate(
                  currencyRates,
                  getCurrencyByCountryCode[singleStore.marketplaceCountry],
                  currentCurrency
                )}
                user={userInfo}
                includeTax={includeTax}
                report={true}
                pageSize={pageSize}
              />
            </Grid>
          );

        case "buyBoxHistory":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <GenericBuyboxTrend
                mid={singleStore.merchantId}
                currentPeriod={currentPeriod}
                timezone={timezone}
                currentRange={currentRange}
                marketplace={singleStore.marketplaceSubtype}
                report={true}
              />
            </Grid>
          );

        case "buyBoxSummary":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <BuyBoxSummary store={singleStore} showFooterLink={false} />
            </Grid>
          );

        case "reviewStatus":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <ReviewSummary
                userInfo={userInfo}
                mid={singleStore.merchantId}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
              />
            </Grid>
          );

        case "externalComments":
          return reportConfig.notes &&
            reportConfig.notes[COMMENTARY_FILTERS.OVERVIEW] ? (
            <Grid key={index} item xs={12} className="panel-grid">
              <CommentsDisplay
                note={reportConfig.notes[COMMENTARY_FILTERS.OVERVIEW]}
                filter={COMMENTARY_FILTERS.OVERVIEW}
              />
            </Grid>
          ) : null;

        case "commentsOverview":
          return reportConfig.notes &&
            reportConfig.notes[COMMENTARY_FILTERS.OVERVIEW] ? (
            <Grid key={index} item xs={12} className="panel-grid">
              <CommentsDisplay
                note={reportConfig.notes[COMMENTARY_FILTERS.OVERVIEW]}
                filter={COMMENTARY_FILTERS.OVERVIEW}
              />
            </Grid>
          ) : null;

        case "commentsCustomer":
          return reportConfig.notes &&
            reportConfig.notes[COMMENTARY_FILTERS.CUSTOMER] ? (
            <Grid key={index} item xs={12} className="panel-grid">
              <CommentsDisplay
                note={reportConfig.notes[COMMENTARY_FILTERS.CUSTOMER]}
                filter={COMMENTARY_FILTERS.CUSTOMER}
              />
            </Grid>
          ) : null;

        case "commentsSales":
          return reportConfig.notes &&
            reportConfig.notes[COMMENTARY_FILTERS.SALES] ? (
            <Grid key={index} item xs={12} className="panel-grid">
              <CommentsDisplay
                note={reportConfig.notes[COMMENTARY_FILTERS.SALES]}
                filter={COMMENTARY_FILTERS.SALES}
              />
            </Grid>
          ) : null;

        case "commentsProfitability":
          return reportConfig.notes &&
            reportConfig.notes[COMMENTARY_FILTERS.PROFITABILITY] ? (
            <Grid key={index} item xs={12} className="panel-grid">
              <CommentsDisplay
                note={reportConfig.notes[COMMENTARY_FILTERS.PROFITABILITY]}
                filter={COMMENTARY_FILTERS.PROFITABILITY}
              />
            </Grid>
          ) : null;

        case "commentsMarketing":
          return reportConfig.notes &&
            reportConfig.notes[COMMENTARY_FILTERS.MARKETING] ? (
            <Grid key={index} item xs={12} className="panel-grid">
              <CommentsDisplay
                note={reportConfig.notes[COMMENTARY_FILTERS.MARKETING]}
                filter={COMMENTARY_FILTERS.MARKETING}
              />
            </Grid>
          ) : null;

        case "commentsOperations":
          return reportConfig.notes &&
            reportConfig.notes[COMMENTARY_FILTERS.OPERATIONS] ? (
            <Grid key={index} item xs={12} className="panel-grid">
              <CommentsDisplay
                note={reportConfig.notes[COMMENTARY_FILTERS.OPERATIONS]}
                filter={COMMENTARY_FILTERS.OPERATIONS}
              />
            </Grid>
          ) : null;

        case "commentsOther":
          return reportConfig.notes &&
            reportConfig.notes[COMMENTARY_FILTERS.OTHER] ? (
            <Grid key={index} item xs={12} className="panel-grid">
              <CommentsDisplay
                note={reportConfig.notes[COMMENTARY_FILTERS.OTHER]}
                filter={COMMENTARY_FILTERS.OTHER}
              />
            </Grid>
          ) : null;

        case "salesByGroup":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <CustomGroups
                userInfo={userInfo}
                mid={singleStore.merchantId}
                chartType={{
                  value: GROUPS_CHART_TYPE.Sales,
                  label: t("customGroupsChart.salesOption"),
                }}
                countryCode={singleStore.marketplaceCountry}
                marketplace={singleStore.marketplaceSubtype}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                shopName={singleStore.storeName}
                report={true}
                selectedGroups={getSelectedConfigurationForWidget(
                  "salesByGroup"
                )}
                conditionalFormatting={conditionalFormatting}
                timezone={timezone}
                includeTax={includeTax}
                pageSize={pageSize}
              />
            </Grid>
          );

        case "marketplaceOverview":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <MarketPlaceOverview
                {...{
                  currentPeriod: currentPeriod,
                  currentRange: currentRange,
                  currentCompare: currentCompare,
                  currentCurrency: currentCurrency,
                  report: true,
                  pageSize: pageSize,
                  includeTax: includeTax,
                  userInfo: userInfo,
                  filteredStores: filteredStores,
                  currentFilter: {
                    marketplaces: [],
                    countries: [],
                    tags: selectedTags,
                  },
                  conditionalFormatting: conditionalFormatting,
                }}
              />
            </Grid>
          );

        case "inventoryStatus":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <InventorySummary
                {...{
                  currentFilter: {
                    marketplaces: [],
                    countries: [],
                    tags: selectedTags,
                  },
                  report: true,
                  pageSize: pageSize,
                  userInfo: userInfo,
                  filteredStores: filteredStores,
                  conditionalFormatting: conditionalFormatting,
                  timezone: timezone,
                }}
              />
            </Grid>
          );

        case "topPerformingProducts":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <TopPerformingProducts
                {...{
                  currentCurrency,
                  currentPeriod,
                  currentRange,
                  currentFilter: {
                    marketplaces: [],
                    countries: [],
                    tags: selectedTags,
                  },
                  pageSize,
                  includeTax,
                  report: true,
                  filteredStores,
                }}
              />
            </Grid>
          );

        case "marketingCampaignSummary":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <CampaignSummary
                {...{
                  currentCurrency,
                  currentRange,
                  pageSize,
                  report: true,
                  currentFilter: {
                    marketplaces: [],
                    countries: [],
                    tags: selectedTags,
                  },
                  filteredStores,
                  conditionalFormatting,
                  timezone,
                  includeTax,
                }}
              />
            </Grid>
          );

        case "profitabilityAndCostTrends":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <ProfitabilityChart
                {...{
                  currentPeriod,
                  currentRange,
                  currentCurrency,
                  timezone,
                  report: true,
                  includeTax,
                  currentFilter: {
                    marketplaces: [],
                    countries: [],
                    tags: selectedTags,
                  },
                }}
              />
            </Grid>
          );

        case "profitabilitySummary":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <ProfitabilitySummary
                {...{
                  pageSize,
                  report: true,
                  currentCurrency,
                  userInfo,
                  currentRange,
                  includeTax,
                  currentFilter: {
                    marketplaces: [],
                    countries: [],
                    tags: selectedTags,
                  },
                  filteredStores: filteredStores,
                  conditionalFormatting: conditionalFormatting,
                  condensed: true,
                }}
              />
            </Grid>
          );

        case "campaignByGroup":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <CampaignCustomGroupTable
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                countryCode={singleStore.marketplaceCountry}
                currentRange={currentRange}
                currentPeriod={currentPeriod}
                currentCurrency={currentCurrency}
                selectedCampaignGroups={getSelectedConfigurationForWidget(
                  "campaignByGroup"
                )}
                pageSize={pageSize}
                report
              />
            </Grid>
          );

        case "profitAndLoss":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <ProfitAndLoss
                title={t("dashboardWidget.profitAndLoss.mainTitle")}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                currentFilter={{
                  marketplaces: [],
                  countries: [],
                  tags: selectedTags,
                }}
                groupByMonth={false}
                includeTax={includeTax}
                report={true}
              />
            </Grid>
          );

        case "advertisingPerformanceTrendOverview":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <MarketingCampaignSummaryChartAndTotals
                {...{
                  currentFilter: {
                    marketplaces: [],
                    countries: [],
                    tags: selectedTags,
                  },
                  currentPeriod: currentPeriod,
                  includeTax: includeTax,
                  currentCurrency: currentCurrency,
                  currentRange: currentRange,
                  showChart: true,
                  timezone,
                }}
              />
            </Grid>
          );

        case "advertisingPerformanceSummaryOverview":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <MarketingCampaignSummaryChartAndTotals
                {...{
                  currentFilter: {
                    marketplaces: [],
                    countries: [],
                    tags: selectedTags,
                  },
                  currentPeriod: currentPeriod,
                  includeTax: includeTax,
                  currentCurrency: currentCurrency,
                  currentRange: currentRange,
                  showTotals: true,
                  conditionalFormatting: conditionalFormatting,
                  timezone,
                }}
              />
            </Grid>
          );

        case "shippedRevenueChart":
          const selectedConfig = getSelectedConfigurationForWidget(
            "shippedRevenueChart"
          );
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <ShippedRevenueTrend
                userInfo={userInfo}
                mid={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : singleStore.merchantId
                }
                marketplaceFilters={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : marketplaceFilters
                }
                currentFilter={
                  reportConfig.reportType === ReportType.MULTI
                    ? {
                        marketplaces: [],
                        countries: [],
                        tags: selectedTags,
                      }
                    : undefined
                }
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                chartType={
                  selectedConfig || {
                    value: SHIPPED_REVENUE_CHART_TYPE.Revenue,
                    label: t("shippedRevenue.revenueOption"),
                  }
                }
                view="sourcing"
                report={true}
                timezone={timezone}
              />
            </Grid>
          );

        case "shippedRevenueChartYTD":
          const selectedChartType = getSelectedConfigurationForWidget(
            "shippedRevenueChartYTD"
          );
          return widget.currentRange && widget.currentPeriod ? (
            <Grid key={index} item xs={12} className="panel-grid">
              <ShippedRevenueTrend
                userInfo={userInfo}
                mid={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : singleStore.merchantId
                }
                marketplaceFilters={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : marketplaceFilters
                }
                currentFilter={
                  reportConfig.reportType === ReportType.MULTI
                    ? {
                        marketplaces: [],
                        countries: [],
                        tags: selectedTags,
                      }
                    : undefined
                }
                currentPeriod={widget.currentPeriod}
                currentRange={widget.currentRange}
                currentCurrency={currentCurrency}
                chartType={
                  selectedChartType || {
                    value: SHIPPED_REVENUE_CHART_TYPE.Revenue,
                    label: t("shippedRevenue.revenueOption"),
                  }
                }
                view="sourcing"
                report={true}
                timezone={timezone}
              />
            </Grid>
          ) : null;

        case "shippedRevenueChartCurrentYear":
        case "shippedRevenueLineChartCurrentYear":
          const selectedChartTypeForCurrentYear =
            getSelectedConfigurationForWidget(widget.id);
          return widget.currentRange && widget.currentPeriod ? (
            <Grid key={index} item xs={12} className="panel-grid">
              <ShippedRevenueTrend
                userInfo={userInfo}
                mid={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : singleStore.merchantId
                }
                marketplaceFilters={
                  reportConfig.reportType === ReportType.MULTI
                    ? undefined
                    : marketplaceFilters
                }
                currentFilter={
                  reportConfig.reportType === ReportType.MULTI
                    ? {
                        marketplaces: [],
                        countries: [],
                        tags: selectedTags,
                      }
                    : undefined
                }
                currentPeriod={widget.currentPeriod}
                currentRange={widget.currentRange}
                currentCurrency={currentCurrency}
                chartType={
                  selectedChartTypeForCurrentYear || {
                    value: SHIPPED_REVENUE_CHART_TYPE.Revenue,
                    label: t("shippedRevenue.revenueOption"),
                  }
                }
                view="sourcing"
                report={true}
                timezone={timezone}
                isLineChart={widget.id === "shippedRevenueLineChartCurrentYear"}
              />
            </Grid>
          ) : null;

        case "shippedRevenueSummary":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <ShippedRevenueTotals
                mid={singleStore.merchantId}
                currentCompare={currentCompare}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                view="sourcing"
                conditionalFormatting={conditionalFormatting}
                report={true}
              />
            </Grid>
          );
        case "shippedRevenueSummaryYTD":
          return widget.currentRange && widget.comparisonPeriod ? (
            <Grid key={index} item xs={12} className="panel-grid">
              <ShippedRevenueTotals
                mid={singleStore.merchantId}
                currentRange={widget.currentRange}
                currentCompare={widget.comparisonPeriod}
                currentCurrency={currentCurrency}
                view="sourcing"
                conditionalFormatting={conditionalFormatting}
                report={true}
              />
            </Grid>
          ) : null;

        case "shippedRevenueTable":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <ShippedRevenueTable
                userInfo={userInfo}
                mid={singleStore.merchantId}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                view="sourcing"
                pageSize={pageSize}
                condensed
                report={true}
                conditionalFormatting={conditionalFormatting}
              />
            </Grid>
          );

        case "orderedRevenueChart":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <OrderedRevenueTrend
                userInfo={userInfo}
                mid={singleStore.merchantId}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                chartType={{
                  value: SHIPPED_REVENUE_CHART_TYPE.Revenue,
                  label: t("orderedRevenue.revenueOption"),
                }}
                report={true}
                timezone={timezone}
              />
            </Grid>
          );

        case "orderedRevenueTable":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <OrderedRevenueTable
                user={userInfo}
                mid={singleStore.merchantId}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                pageSize={pageSize}
                condensed
                report={true}
                conditionalFormatting={conditionalFormatting}
                timezone={timezone}
              />
            </Grid>
          );

        case "orderedRevenueSummary":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <OrderedRevenueTotals
                userInfo={userInfo}
                mid={singleStore.merchantId}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                currentCompare={currentCompare}
                conditionalFormatting={conditionalFormatting}
                report={true}
              />
            </Grid>
          );

        case "vendorGroupAnalytics":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <CustomVendorGroups
                userInfo={userInfo}
                actions={undefined}
                selectedGroups={getSelectedConfigurationForWidget(
                  "vendorGroupAnalytics"
                )}
                chartType={{
                  value: GROUP_REVENUE_CHART_TYPE.OrderedRevenue,
                  label: t("customVendorGroupsChart.orderedRevenueOption"),
                }}
                mid={singleStore.merchantId}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                countryCode={singleStore.marketplaceCountry}
                marketplace={singleStore.marketplaceType}
                shopName={singleStore.storeName}
                currentCurrency={currentCurrency}
                timezone={timezone}
                includeTax={includeTax}
                report={true}
                isSourcingView={true}
                currentCompare={currentCompare}
                pageSize={pageSize}
              />
            </Grid>
          );

        case "inventoryHealthChart":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <InventoryHealthTrend
                userInfo={userInfo}
                mid={singleStore.merchantId}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                currentPeriod={currentPeriod}
                chartType={{
                  value: INVENTORY_HEALTH_TABLE_TYPE.Units,
                  label: t("inventoryHealth.unitsOption"),
                }}
                view="units"
                condensed={true}
                report={true}
                timezone={timezone}
              />
            </Grid>
          );

        case "inventoryHealthSummary":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <InventoryHealthTotals
                userInfo={userInfo}
                mid={singleStore.merchantId}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                currentCompare={currentCompare}
                conditionalFormatting={conditionalFormatting}
              />
            </Grid>
          );

        case "inventoryHealthTable":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <InventoryHealthTable
                user={userInfo}
                mid={singleStore.merchantId}
                currentCurrency={currentCurrency}
                currentRange={currentRange}
                tableType={{
                  value: INVENTORY_HEALTH_TABLE_TYPE.Units,
                  label: t("inventoryHealth.unitsOption"),
                }}
                pageSize={pageSize}
                condensed={true}
                conditionalFormatting={conditionalFormatting}
                report={true}
              />
            </Grid>
          );

        case "missedShippedCogs":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <SourcingShareTrend
                userInfo={userInfo}
                mid={singleStore.merchantId}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                currentPeriod={currentPeriod}
                chartType={{
                  value: SOURCING_SHARE_CHART_TYPE.LostCogs,
                  label: t("sourcingShare.lostCogs"),
                }}
                report={true}
                timezone={timezone}
              />
            </Grid>
          );

        case "shippedUnitsSummary":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <ShippedUnitsSummary
                user={userInfo}
                mid={singleStore.merchantId}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                currentFilter={{
                  marketplaces: [],
                  countries: [],
                  tags: selectedTags,
                }}
                report={true}
                country={singleStore.marketplaceCountry}
              />
            </Grid>
          );

        case "sourcingShareByProduct":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <SourcingShareTable
                user={userInfo}
                mid={singleStore.merchantId}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                currentPeriod={currentPeriod}
                pageSize={pageSize}
                condensed={true}
                conditionalFormatting={conditionalFormatting}
                report={true}
                country={singleStore.marketplaceCountry}
              />
            </Grid>
          );

        case "trafficAndConversionTrend":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <TrafficAndConversionTrend
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                currentRange={currentRange}
                currentPeriod={currentPeriod}
                currentCurrency={currentCurrency}
                timezone={timezone}
              />
            </Grid>
          );
        case "trafficAndConversionTrendYTD":
          return widget.currentRange && widget.currentPeriod ? (
            <Grid key={index} item xs={12} className="panel-grid">
              <TrafficAndConversionTrend
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                currentRange={widget.currentRange}
                currentPeriod={widget.currentPeriod}
                currentCurrency={currentCurrency}
                timezone={timezone}
              />
            </Grid>
          ) : null;

        case "trafficAndConversionTotals":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <TrafficAndConversionTotals
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                currentRange={currentRange}
                currentCompare={currentCompare}
                currentCurrency={currentCurrency}
                conditionalFormatting={conditionalFormatting}
              />
            </Grid>
          );
        case "trafficMixTrend":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <TrafficMixTrend
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                currentRange={currentRange}
                currentPeriod={currentPeriod}
                currentCurrency={currentCurrency}
                timezone={timezone}
              />
            </Grid>
          );
        case "trafficAndConversionTableParents":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <TrafficAndConversionTable
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                currentRange={currentRange}
                currentPeriod={currentPeriod}
                currentCurrency={currentCurrency}
                view={TrafficAndConversionTableSelect.PARENTS}
                pageSize={pageSize}
                condensed={true}
                report={true}
                timezone={timezone}
                conditionalFormatting={conditionalFormatting}
              />
            </Grid>
          );
        case "trafficAndConversionTableSkus":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <TrafficAndConversionTable
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                currentRange={currentRange}
                currentPeriod={currentPeriod}
                currentCurrency={currentCurrency}
                view={TrafficAndConversionTableSelect.SKUS}
                pageSize={pageSize}
                condensed={true}
                report={true}
                timezone={timezone}
                conditionalFormatting={conditionalFormatting}
              />
            </Grid>
          );
        case "trafficAndConversionNormalVsB2BTotals":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <TrafficAndConversionNormalVsB2BTotals
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
              />
            </Grid>
          );
        case "salesByParent":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <SalesByParentWidget
                mid={singleStore.merchantId}
                countryCode={singleStore.marketplaceCountry}
                currentRange={currentRange}
                currentPeriod={currentPeriod}
                currentCurrency={currentCurrency}
                pageSize={pageSize}
                conditionalFormatting={conditionalFormatting}
                report={true}
                shopName={singleStore.storeName}
                userInfo={userInfo}
                includeTax={includeTax}
                marketplace={singleStore.marketplaceType}
                condensed={true}
              />
            </Grid>
          );
        case "inventoryReplenishment":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <InventoryReplenishmentTable
                {...{
                  user: userInfo,
                  mid: singleStore.merchantId,
                  marketplaceType: singleStore.marketplaceType,
                  marketplaceSubtype: singleStore.marketplaceSubtype || "",
                  countryCode: singleStore.marketplaceCountry,
                  currentCurrency,
                  selectedTimezone: timezone,
                  report: true,
                  pageSize: pageSize,
                  activeProducts: true,
                  actions: (
                    <DownloadCsv
                      {...{
                        reportType: "inventoryReplenishment",
                        path: "/api/amazon/inventory/replenishment",
                        mid: singleStore.merchantId,
                        params: {
                          storeName: singleStore.storeName,
                          marketplaceType: singleStore.marketplaceType,
                          marketplaceSubtype: singleStore.marketplaceSubtype,
                          countryCode: singleStore.marketplaceCountry,
                          timezone,
                          searchText: "",
                          currentCurrency,
                          exchangeRate: getExchangeRate(
                            currencyRates,
                            getCurrencyByCountryCode[
                              singleStore.marketplaceCountry
                            ],
                            currentCurrency
                          ),
                        },
                      }}
                    />
                  ),
                }}
              />
            </Grid>
          );
        case "fbaInventoryStatus":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <InventoryStatus
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={singleStore.marketplaceSubtype || ""}
                marketplaceCountry={singleStore.marketplaceCountry}
              />
            </Grid>
          );

        case "subscribeAndSaveProductTable":
          return (
            <SubscribeAndSaveTable
              mid={singleStore.merchantId}
              marketplaceType={singleStore.marketplaceType}
              marketplaceSubtype={
                singleStore.marketplaceSubtype || singleStore.marketplaceType
              }
              countryCode={singleStore.marketplaceCountry}
              currentRange={currentRange}
              currentCurrency={currentCurrency}
              pageSize={pageSize}
              report
            />
          );

        case "refundAnalyticsChart":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <RefundAnalyticsChart
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={
                  singleStore.marketplaceSubtype || singleStore.marketplaceType
                }
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                currentPeriod={currentPeriod}
                timezone={timezone}
                includeTax={includeTax}
                chartOption={{
                  value: REFUND_ANALYTICS_CHART_TYPE.Refunds,
                  label: t("refundAnalytics.chartSelect.refunds"),
                }}
                report={true}
              />
            </Grid>
          );

        case "refundAnalyticsSummary":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <RefundAnalyticsSummary
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={
                  singleStore.marketplaceSubtype || singleStore.marketplaceType
                }
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                countryCode={singleStore.marketplaceCountry}
                currentCompare={currentCompare}
                includeTax={includeTax}
              />
            </Grid>
          );

        case "refundAnalyticsByProduct":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <RefundAnalyticsProductTable
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={
                  singleStore.marketplaceSubtype || singleStore.marketplaceType
                }
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                includeTax={includeTax}
                pageSize={pageSize}
                countryCode={singleStore.marketplaceCountry}
                currentPeriod={currentPeriod}
                currencyRates={currencyRates}
                report={true}
              />
            </Grid>
          );
        case "customerLTVSummary":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <CLVCohortSummaryPanel
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={
                  singleStore.marketplaceSubtype || singleStore.marketplaceType
                }
                countryCode={singleStore.marketplaceCountry}
                currentCurrency={currentCurrency}
                currentCompare={currentCompare}
                currentRange={currentRange}
              />
            </Grid>
          );

        case "customerLTVTable":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <CLVCohortPurchaseBehaviour
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={
                  singleStore.marketplaceSubtype || singleStore.marketplaceType
                }
                countryCode={singleStore.marketplaceCountry}
                currentCurrency={currentCurrency}
                currentRange={currentRange}
                timezone={timezone}
                report
              />
            </Grid>
          );

        case "customerLTVTrend": {
          const selectedChartType = getSelectedConfigurationForWidget(
            widget.id
          );
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <CLVCohortTrendChart
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={
                  singleStore.marketplaceSubtype || singleStore.marketplaceType
                }
                countryCode={singleStore.marketplaceCountry}
                currentCurrency={currentCurrency}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                timezone={timezone}
                chartType={
                  selectedChartType || {
                    value: LTV_COHORT_TREND_CHART_TYPE.Customer,
                    label: t("customerLifetimeValueChart.cohortTrendCustomers"),
                  }
                }
              />
            </Grid>
          );
        }

        case "repeatPurchaseSummary":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <RepeatPurchaseSummary
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={
                  singleStore.marketplaceSubtype || singleStore.marketplaceType
                }
                countryCode={singleStore.marketplaceCountry}
                currentCurrency={currentCurrency}
                currentRange={currentRange}
                currentCompare={currentCompare}
                includeTax={includeTax}
              />
            </Grid>
          );

        case "repeatPurchaseTrend": {
          const selectedChartType = getSelectedConfigurationForWidget(
            widget.id
          );
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <SalesByCustomerType
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={
                  singleStore.marketplaceSubtype || singleStore.marketplaceType
                }
                countryCode={singleStore.marketplaceCountry}
                currentCurrency={currentCurrency}
                currentPeriod={currentPeriod}
                currentRange={currentRange}
                timezone={timezone}
                includeTax={includeTax}
                chartType={
                  selectedChartType || {
                    value: REPEAT_PURCHASE_CHART_TYPE.Customers,
                    label: t("myStoresWidget.salesByCustomerType.customers"),
                  }
                }
              />
            </Grid>
          );
        }

        case "repeatPurchaseTable":
          return (
            <Grid key={index} item xs={12} className="panel-grid">
              <RepeatPurchaseByProduct
                mid={singleStore.merchantId}
                marketplaceType={singleStore.marketplaceType}
                marketplaceSubtype={
                  singleStore.marketplaceSubtype || singleStore.marketplaceType
                }
                countryCode={singleStore.marketplaceCountry}
                currentCurrency={currentCurrency}
                currentRange={currentRange}
                includeTax={includeTax}
                pageSize={pageSize}
                report
              />
            </Grid>
          );

        default: {
          if (widget.id.includes("commentsMultichannel")) {
            return reportConfig.notes &&
              widget.options?.tag &&
              reportConfig.notes[widget.options.tag] ? (
              <CommentsDisplay
                note={reportConfig.notes[widget.options.tag]}
                tag={widget.options.tag}
              />
            ) : null;
          }

          return null;
        }
      }
    });
  };

  const reportName = get(reportConfig, "name");
  const fromDisplayDate = moment
    .unix(currentRange.fromDate)
    .tz(timezone)
    .locale(i18next.language)
    .format("D MMM YY");
  const toDisplayDate = moment
    .unix(currentRange.toDate)
    .tz(timezone)
    .locale(i18next.language)
    .format("D MMM YY");
  const priorFromDisplayDate = moment
    .unix(currentRange.priorFromDate)
    .tz(timezone)
    .locale(i18next.language)
    .format("D MMM YY");
  const priorToDisplayDate = moment
    .unix(currentRange.priorToDate)
    .tz(timezone)
    .locale(i18next.language)
    .format("D MMM YY");

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>
            {reportName}: {fromDisplayDate} - {toDisplayDate}
          </title>
        </Helmet>
        <ClientReportToolbar
          reportName={reportName}
          preparedBy={preparedBy}
          createdAt={clientReport?.createdAt}
          fromDisplayDate={fromDisplayDate}
          toDisplayDate={toDisplayDate}
        />
        <PageBlock report={true}>
          <ReportWrapper className="report-wrapper">
            <Box className="print-margin">
              <ClientReportHeader
                reportName={reportName}
                fromDisplayDate={fromDisplayDate}
                toDisplayDate={toDisplayDate}
                priorFromDisplayDate={priorFromDisplayDate}
                priorToDisplayDate={priorToDisplayDate}
                currency={currentCurrency}
                timezone={timezone}
                createdAt={clientReport?.createdAt}
                stores={filteredStores}
              />
              <Grid
                container
                spacing={2}
                alignItems="stretch"
                className="panel-grid"
              >
                {renderWidgets()}
              </Grid>
            </Box>
          </ReportWrapper>
        </PageBlock>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};
